import currency from 'currency.js';
import { format } from 'date-fns';

export const euro = (value, options) => {
    return currency(value, {
        symbol: '',
        separator: '.',
        decimal: ',',
        fromCents: true,
        ...options,
    });
};
export const round2d = value => {
    return currency(value, { symbol: '', separator: '.', decimal: ',', fromCents: false });
};

export const priceToEuroString = price => {
    return euro(price).format() + ' €';
};

export const distanceToString = (distance, unit, suffix = 'km') => {
    if (unit === 'km') {
        return `${round2d(distance)
            .format()
            .replace(',00', '')}${suffix}`;
    }
    return `${round2d(distance / 1000).format()}${suffix}`;
};

export const percentageToString = percentage => {
    return `${round2d(percentage * 100).format()} %`;
};

export const timeToString = time => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);

    return `${hours}:${minutes}`;
};

export const dateToString = date => {
    if (!date) {
        return '';
    }
    return format(new Date(date), 'dd.MM.yyyy');
};

export const getDuration = seconds => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - hours * 3600) / 60);

    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }

    return `${hours}:${minutes}`;
};
