<template>
    <div class="Empty-State">
        <span class="Empty-Text" v-html="message" />

        <img :src="img" class="Empty-Image" />
    </div>
</template>
<script>
export default {
    name: 'EmptyState',
    props: {
        message: {
            type: String,
            default: `Keine Daten vorhanden für den ausgewählten Zeitraum und Fahrer.
            <br />
            Bitte wählen Sie einen anderen Zeitraum und/oder einen anderen Fahrer.`,
        },
    },
    computed: {
        img() {
            const img = Math.random() < 0.5 ? 'empty-state' : 'empty-state-2';
            return require(`@/assets/images/${img}.png`);
        },
    },
};
</script>
<style lang="scss" scoped>
.Empty-State {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 0;

    .Empty-Image {
        width: 500px;
        object-fit: cover;
        border-radius: 100px;

        @media (max-width: breakpoint(tabletLandscape)) {
            width: 550px;
        }

        @media (max-width: breakpoint(mobile)) {
            width: 100%;
            border-radius: 30px;
        }
    }

    .Empty-Text {
        font-size: 1.25rem;
        color: var(--color-black);
        margin-bottom: 30px;
        text-align: center;
        line-height: 1.5;

        @media (max-width: breakpoint(tabletLandscape)) {
            font-size: 1rem;
            text-align: left;
        }
    }
}
</style>
