var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SavingsCalculator",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', {
    attrs: {
      "highlight": "Rücklagenrechner"
    }
  }, [_c('div', [_c('Button', {
    attrs: {
      "variant": "basic"
    },
    on: {
      "onClick": _vm.showCsvImportModal
    }
  }, [_vm._v(" CSV Importieren ")])], 1)]), _c('ChartV2', {
    attrs: {
      "groupByService": "",
      "renderSeparately": "",
      "direction": "horizontal",
      "type": "bar",
      "title": "Einnahmen und Ausgaben",
      "secondValues": _vm.chartComparisonData,
      "values": _vm.chartData,
      "serviceColors": _vm.serviceColors
    }
  }), !_vm.isFetchingData ? _c('div', {
    staticClass: "SavingsCalculator-Tables"
  }, [_c('div', {
    staticStyle: {
      "border": "solid 1px rgba(0,0,0,0.1)",
      "border-radius": "9px",
      "padding": "12px"
    }
  }, [_c('Toolbar', [_c('div', [_c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "size": "small",
      "items": _vm.employeesOptions,
      "selected": _vm.selectedEmployee,
      "placeholder": "Fahrer Wählen"
    },
    on: {
      "onItemSelect": _vm.handleEmployeeSelect
    }
  }), _c('Datepicker', {
    attrs: {
      "size": "small",
      "startDate": _vm.selectedFrom,
      "endDate": _vm.selectedTo,
      "hasQuickActions": false
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Button', {
    attrs: {
      "isLoading": _vm.isFetchingData,
      "size": "small"
    },
    on: {
      "onClick": _vm.handleQuery
    }
  }, [_vm._v(" Anzeigen ")])], 1)]), _c('h5', [_vm._v("Abgerechnete Fahrer")]), _vm.completedEmployees.length ? _c('EmployeeTable', {
    staticClass: "SavingsCalculator-Table-Completed",
    attrs: {
      "employees": _vm.completedEmployees,
      "title": "Abgerechnete Fahrer",
      "hasBadge": ""
    },
    on: {
      "onPayClick": _vm.navigateToEmployee,
      "onRowClick": function onRowClick(props) {
        return _vm.handleRowClick(Object.assign({}, props, {
          type: 'employee'
        }));
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticStyle: {
      "margin-top": "42px"
    }
  }, [_c('h5', [_vm._v("Offene Fahrer")]), _vm.unfinishedEmployees.length ? _c('EmployeeTable', {
    attrs: {
      "employees": _vm.allUnfinishedEmployees,
      "title": "Offene Fahrer",
      "hasBadge": ""
    },
    on: {
      "onPayClick": _vm.navigateToEmployee,
      "onRowClick": function onRowClick(props) {
        return _vm.handleRowClick(Object.assign({}, props, {
          type: 'employee'
        }));
      }
    }
  }) : _vm._e()], 1)]) : _c('EmptyState'), _c('Modal', {
    attrs: {
      "title": 'Importieren Sie Ihre Daten',
      "show": _vm.csvImportModal.active,
      "action": {
        text: 'Daten Importieren',
        color: 'dark',
        callback: _vm.importCsv
      },
      "cancel": {
        text: 'Abbrechen'
      }
    },
    on: {
      "onModalClose": _vm.handleCsvImportModalClose
    }
  }, [_c('p', [_vm._v(" Laden Sie Ihre CSV von "), _c('a', {
    attrs: {
      "href": "https://portal.free-now.com/earnings",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v("FreeNow")]), _vm._v(", Uber, "), _c('a', {
    attrs: {
      "href": "https://fleets.bolt.eu/v2/reports",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v("Bolt")]), _vm._v(" und "), _c('a', {
    attrs: {
      "href": "https://fleet.bliq.app/earnings",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v("Bliq")]), _vm._v(". hier hoch um sie in taxiFusion einzupflegen. ")]), _c('br'), _c('div', {
    staticClass: "FileDropArea",
    on: {
      "dragover": function dragover($event) {
        $event.preventDefault();
      },
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.handleCsvDrop.apply(null, arguments);
      }
    }
  }, [_c('p', [_vm._v(" CSV Datei hier einfügen oder "), _c('br'), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.triggerFileInput.apply(null, arguments);
      }
    }
  }, [_vm._v(" suchen Sie sie in Ihrem Computer ")])]), _c('input', {
    ref: "fileInput",
    attrs: {
      "type": "file",
      "accept": ".csv",
      "hidden": ""
    },
    on: {
      "change": _vm.handleCsvChange
    }
  })]), _vm.csvImportModal.file ? _c('div', [_c('strong', [_vm._v("Datei:")]), _vm._v(" " + _vm._s(_vm.csvImportModal.file.name) + " ")]) : _vm._e()]), _c('Modal', {
    staticClass: "modalmodal",
    attrs: {
      "title": 'Weisen Sie Ihre Fahrer zu',
      "show": true,
      "action": {
        text: 'Daten Speichern',
        color: 'dark',
        callback: function callback() {}
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "closeOnBackdropClick": false
    }
  }, _vm._l(_vm.foundUsers, function (user) {
    return _c('div', {
      key: user.id,
      staticStyle: {
        "display": "flex",
        "justify-content": "space-between"
      }
    }, [_c('span', {
      staticStyle: {
        "font-size": "12px"
      }
    }, [_vm._v(_vm._s(user.value))]), _c('Dropdown', {
      attrs: {
        "hasSearch": "",
        "size": "small",
        "items": _vm.employeesOptions,
        "selected": _vm.closestUserToEmployee(user),
        "placeholder": "Fahrer Wählen"
      },
      on: {
        "onItemSelect": _vm.handleEmployeeSelect
      }
    })], 1);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }