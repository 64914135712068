<template>
    <div class="Checkbox" :class="[{ 'Checkbox--hasError': hasError }, `Checkbox--${size}`]">
        <input
            type="checkbox"
            :id="name || value"
            :name="name"
            :disabled="isDisabled"
            :checked="value"
            @change="handleChange"
        />
        <label :for="name">
            <slot />
        </label>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'medium',
        },
        name: String,
        hasError: Boolean,
        isDisabled: Boolean,
        value: Boolean, // v-model usage requires 'value' (checkbox model) to be passed as a prop
    },
    methods: {
        handleChange(event) {
            const isChecked = event.target.checked;

            // Emit the changed value to the parent
            this.$emit('onCheck', isChecked);

            // Optional: if parent uses v-model, this syncs the value
            this.$emit('input', isChecked);
        },
    },
};
</script>

<style lang="scss" scoped>
.Checkbox {
    display: flex;
    margin: 10px 0;

    &.Checkbox--hasError {
        color: color(red);
    }

    &.Checkbox--small {
        display: flex;
        align-items: center;
        margin: 0;

        a,
        label {
            margin: 0;
            font-size: 12px;
        }

        input {
            margin-top: 0;
            margin-right: 5px;
            width: 15px;
            height: 15px;
        }
    }

    input {
        margin: 0;
        margin-right: 10px;
        margin-top: -2px;
        cursor: pointer;
        width: 20px;
        height: 20px;

        &:focus,
        &:active {
            outline: 1px solid var(--color-blue-light);
        }
    }

    input[type='checkbox'] {
        border-radius: 0px;
    }

    label,
    a {
        font-size: 18px;
        line-height: 1.5;
        user-select: none;
        margin-top: -4px;
        color: inherit;
        flex: 1;
        cursor: pointer;
    }

    .Checkbox-Description {
        font-size: 12px;
        line-height: 1rem;
        display: block;
        color: color(gray-dark);
    }
}
</style>
