<template>
    <div class="SavingsCalculator" :class="{ 'is-loading': isFetchingData }">
        <!-- Updated headline -->
        <Headline highlight="Rücklagenrechner">
            <!-- <div class="SavingsCalculator-Summary">
          <p style="color:red">Unerledigte Fahrer: {{ missedEmployees.length }}</p>
          <p>Abgerechnete Fahrer: {{ completedEmployees.length }}</p>
          <p>Offene Fahrer: {{ unfinishedEmployees.length }}</p>
      </div> -->
            <div>
                <Button @onClick="showCsvImportModal" variant="basic">
                    CSV Importieren
                </Button>
            </div>
        </Headline>
        <ChartV2
            groupByService
            renderSeparately
            direction="horizontal"
            type="bar"
            title="Einnahmen und Ausgaben"
            :secondValues="chartComparisonData"
            :values="chartData"
            :serviceColors="serviceColors"
        />

        <!-- Displaying tables if there is any data -->
        <div class="SavingsCalculator-Tables" v-if="!isFetchingData">
            <div style="border: solid 1px rgba(0,0,0,0.1); border-radius: 9px; padding: 12px">
                <Toolbar>
                    <div>
                        <Dropdown
                            hasSearch
                            size="small"
                            :items="employeesOptions"
                            :selected="selectedEmployee"
                            placeholder="Fahrer Wählen"
                            @onItemSelect="handleEmployeeSelect"
                        />
                        <Datepicker
                            size="small"
                            @onChange="handleDateChange"
                            :startDate="selectedFrom"
                            :endDate="selectedTo"
                            :hasQuickActions="false"
                        />
                        <Button @onClick="handleQuery" :isLoading="isFetchingData" size="small">
                            Anzeigen
                        </Button>
                    </div>
                </Toolbar>

                <h5>Abgerechnete Fahrer</h5>
                <EmployeeTable
                    class="SavingsCalculator-Table-Completed"
                    v-if="completedEmployees.length"
                    :employees="completedEmployees"
                    title="Abgerechnete Fahrer"
                    hasBadge
                    @onPayClick="navigateToEmployee"
                    @onRowClick="props => handleRowClick({ ...props, type: 'employee' })"
                />
            </div>
            <div style="margin-top: 42px;">
                <h5>Offene Fahrer</h5>
                <EmployeeTable
                    v-if="unfinishedEmployees.length"
                    :employees="allUnfinishedEmployees"
                    title="Offene Fahrer"
                    hasBadge
                    @onPayClick="navigateToEmployee"
                    @onRowClick="props => handleRowClick({ ...props, type: 'employee' })"
                />
            </div>
        </div>

        <!-- Empty state when there's no data -->
        <EmptyState v-else />
        <Modal
            :title="'Importieren Sie Ihre Daten'"
            :show="csvImportModal.active"
            :action="{ text: 'Daten Importieren', color: 'dark', callback: importCsv }"
            :cancel="{ text: 'Abbrechen' }"
            @onModalClose="handleCsvImportModalClose"
        >
            <p>
                Laden Sie Ihre CSV von
                <a
                    href="https://portal.free-now.com/earnings"
                    target="_blank"
                    rel="noopener noreferrer"
                    >FreeNow</a
                >, Uber,
                <a
                    href="https://fleets.bolt.eu/v2/reports"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Bolt</a
                >
                und
                <a href="https://fleet.bliq.app/earnings" target="_blank" rel="noopener noreferrer"
                    >Bliq</a
                >. hier hoch um sie in taxiFusion einzupflegen.
            </p>
            <br />

            <!-- Drag and Drop or File Input -->
            <div class="FileDropArea" @dragover.prevent @drop.prevent="handleCsvDrop">
                <p>
                    CSV Datei hier einfügen oder
                    <br />
                    <a href="#" @click.prevent="triggerFileInput">
                        suchen Sie sie in Ihrem Computer
                    </a>
                </p>
                <input type="file" ref="fileInput" @change="handleCsvChange" accept=".csv" hidden />
            </div>
            <div v-if="csvImportModal.file">
                <strong>Datei:</strong> {{ csvImportModal.file.name }}
            </div>
        </Modal>
        <Modal
            class="modalmodal"
            :title="'Weisen Sie Ihre Fahrer zu'"
            :show="true"
            :action="{ text: 'Daten Speichern', color: 'dark', callback: () => {} }"
            :cancel="{ text: 'Abbrechen' }"
            :closeOnBackdropClick="false"
        >
            <div
                v-for="user in foundUsers"
                :key="user.id"
                style="display: flex; justify-content: space-between;"
            >
                <span style="font-size:12px">{{ user.value }}</span>

                <Dropdown
                    hasSearch
                    size="small"
                    :items="employeesOptions"
                    :selected="closestUserToEmployee(user)"
                    placeholder="Fahrer Wählen"
                    @onItemSelect="handleEmployeeSelect"
                ></Dropdown>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/widgets/Modal.vue';
import Input from '@/components/widgets/Input.vue';
import { euro } from '@/lib/helper';
import Fuse from 'fuse.js';

import { startOfDay, endOfDay, startOfMonth, addDays } from 'date-fns';
import { faker } from '@faker-js/faker';
import Datepicker from '@/components/widgets/Datepicker';
import ChartV2 from '@/components/widgets/ChartV2';
// import Download from '@/components/widgets/Download';
import Button from '@/components/widgets/Button';
import Dropdown from '@/components/widgets/Dropdown';
import Headline from '@/components/Headline';
import Toolbar from '@/components/Toolbar';
import EmptyState from '@/components/EmptyState.vue';
import EmployeeTable from './components/EmployeeTable.vue';

export default {
    name: 'SavingsCalculator',
    components: {
        Datepicker,
        // Download,
        ChartV2,
        Button,
        Dropdown,
        Headline,
        Toolbar,
        EmptyState,
        EmployeeTable,
        Modal,
    },
    data: () => {
        return {
            csvImportModal: {
                active: false,
                file: null,
            },
            selectedEmployee: null,
            selectedFrom: startOfMonth(new Date()),
            selectedTo: endOfDay(new Date()),
            isFetchingData: false,
            employees: [],
            missedEmployees: [],
            completedEmployees: [],
            unfinishedEmployees: [],
            selectedFilter: { id: 'all', value: 'Alle' },
            expenses: [],
            categories: [
                { value: 'Alle', id: 'all', hidden: true },
                {
                    value: 'Rücklage',
                    id: 'saving',
                    hidden: true,
                    inFilterHidden: true,
                    percent: '47%',
                },
                { value: 'Uber', id: 'uber', percent: '100%', color: '#5C5C60' }, // Slightly desaturated dark gray
                { value: 'FreeNow', id: 'freeNow', percent: '50%', color: '#D85670' }, // Muted reddish-pink
                { value: 'Bolt', id: 'bolt', percent: '100%', color: '#5FC9B3' }, // Softer teal-green
                { value: 'Bliq', id: 'bliq', percent: '100%', color: '#F472A5' }, // Muted pink
                { value: 'Coupon', id: 'coupon', percent: '100%', color: '#7FDA97' }, // Muted green
                { value: 'Apcoa', id: 'apcoa', percent: '100%', color: '#55C0E0' }, // Softer cyan-blue
                { value: 'Funk', id: 'funk', percent: '100%', color: '#F4D05D' }, // Softer yellow
                { value: 'Tanken', id: 'tanken', type: 'expense', color: '#F99E60' }, // Softer orange
                { value: 'Werkstatt', id: 'werkstatt', type: 'expense', color: '#8967E0' }, // Softer purple
                { value: 'Wäsche', id: 'autowäsche', type: 'expense', color: '#5EA8F7' }, // Softer blue
                { value: 'TÜV', id: 'tuv', type: 'expense', color: '#66A7D6' }, // Softer lighter blue
                {
                    value: 'SV',
                    id: 'sozialversicherung',
                    percent: '100%',
                    type: 'expense',
                    color: '#E67272',
                }, // Muted red
                { value: 'Sonstiges', id: 'custom', color: '#BFC1C6' }, // Softer gray
            ],
            revenues: [],
            foundUsers: [],
            showMatchUserWithEmployee: true,
        };
    },
    computed: {
        chartData() {
            const data = this.filteredRevenue
                .map(({ amount, date, ...rest }) => ({
                    formattedValue: euro(amount).format(),
                    formatter: value => euro(value).format(),
                    rawDate: date, // Make sure this value is used for x-axis categories
                    value: amount,
                    date: date,
                    ...rest,
                }))
                .sort((a, b) => new Date(a.date) - new Date(b.date)); // Pie chart only for revenues

            return data;
        },
        chartComparisonData() {
            const expenses = this.filteredExpenses
                .map(({ amount, date, ...rest }) => ({
                    formattedValue: euro(amount).format(),
                    formatter: value => euro(value).format(),
                    rawDate: date, // Make sure this value is used for x-axis categories
                    value: amount,
                    date: date,
                    ...rest,
                }))
                .sort((a, b) => new Date(a.date) - new Date(b.date)); // Pie chart only for revenues

            return expenses;
        },
        serviceColors() {
            // Create a lookup table from categories
            return this.categories.reduce((acc, category) => {
                if (category.color) {
                    acc[category.value] = category.color;
                }
                return acc;
            }, {});
        },
        filteredExpenses() {
            const filtered =
                this.selectedFilter.id === 'all'
                    ? this.expenses
                    : this.expenses.filter(
                          expense => expense.service === this.selectedFilter.value,
                      );

            return filtered;
        },

        filteredRevenue() {
            const filtered =
                this.selectedFilter.id === 'all'
                    ? this.revenues
                    : this.revenues.filter(
                          revenue => revenue.service === this.selectedFilter.value,
                      );

            return filtered;
        },
        expensesAndRevenue() {
            const filteredExpenses =
                this.selectedFilter.id === 'all'
                    ? this.expenses
                    : this.expenses.filter(
                          expense => expense.service === this.selectedFilter.value,
                      );

            const combinedEntries = filteredExpenses
                .map(entry => ({
                    value: entry.type === 'Expense' ? entry.description : 'Revenue',
                    income: entry.type === 'Revenue' ? entry.amount : 0,
                    expense: entry.type === 'Expense' ? -entry.amount : 0,
                    isCompleted: entry.type === 'Revenue',
                    completedAt: entry.type === 'Revenue' ? entry.date : null,
                    color: entry.color,
                    ...entry,
                }))
                .sort(
                    (a, b) => new Date(a.completedAt || a.date) - new Date(b.completedAt || b.date),
                );

            return combinedEntries;
        },

        totalExpenseAmount() {
            return this.expensesAndRevenue.reduce((acc, entry) => {
                return acc + (entry.expense ? entry.expense : 0);
            }, 0);
        },

        employeesOptions() {
            return this.completedEmployees.map(e => ({
                ...e,
                label: `${e.firstName} ${e.lastName}`,
            }));
        },
        allUnfinishedEmployees() {
            return this.missedEmployees.concat(this.unfinishedEmployees).sort((a, b) => {
                return a.isUnfinishedPast > b.isUnfinishedPast;
            });
        },
    },
    methods: {
        closestUserToEmployee(user) {
            const options = {
                minMatchCharLength: 2,
                threshold: 0.2,
                key: ['value'],
            };
            const fuse = new Fuse(this.employeesOptions, options);
            const result = fuse.search(user.value);
            return result[0];
        },
        generateRandomExpenses(count = 30) {
            const entries = [];

            for (let i = 0; i < count; i++) {
                // Randomly decide whether to generate an Expense or Revenue entry
                const isExpense = faker.datatype.boolean();

                // Choose categories based on whether they should be Expense or Revenue
                const allowedCategories = isExpense
                    ? this.categories.filter(
                          c => !c.hidden && (c.type === 'expense' || c.type === undefined),
                      )
                    : this.categories.filter(c => !c.hidden && c.type !== 'expense');

                // Select a random allowed category
                const selectedCategory = faker.helpers.arrayElement(allowedCategories);

                const description = `${selectedCategory.value} Service Fee`;
                const amount =
                    parseFloat(faker.finance.amount({ min: 5, max: 100, fixed: 2 })) * 100;

                entries.push({
                    id: faker.string.uuid(),
                    type: isExpense ? 'Expense' : 'Revenue',
                    description: description,
                    date: faker.date
                        .between({ from: startOfMonth(new Date()), to: new Date() })
                        .toISOString(),
                    amount: amount,
                    service: selectedCategory.value,
                    color: selectedCategory.color,
                });
            }

            // Split the entries into expenses and revenues
            this.expenses = entries.filter(entry => entry.type === 'Expense');
            this.revenues = entries.filter(entry => entry.type === 'Revenue');
        },
        handleCsvImportModalClose() {
            this.csvImportModal.active = false;
            this.csvImportModal.file = null;
        },
        handleCategorySelect({ item }) {
            this.selectedCategory = item;
            if (item.id !== 'custom') {
                this.expenseModal.category = item.value;
            } else {
                this.expenseModal.category = '';
            }
        },
        handleAmountChange({ value }) {
            this.expenseModal.amount = value;
        },
        handleCsvChange(event) {
            const file = event.target.files[0];
            if (file && file.type === 'text/csv') {
                this.csvImportModal.file = file;
            } else {
                console.warn('Invalid file type. Only .csv files are supported.');
            }
        },
        handleCsvDrop(event) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if (file && (file.type === 'text/csv' || file.name.endsWith('.csv'))) {
                this.csvImportModal.file = file;
            } else {
                console.warn('Invalid file type. Only .csv files are supported.');
            }
        },
        importCsv() {
            if (this.csvImportModal.file) {
                console.log('Importing CSV:', this.csvImportModal.file.name);
                // Add your logic for handling the CSV file import.
                this.handleCsvImportModalClose();
            } else {
                console.warn('No CSV file selected for import.');
            }
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        generateEmployees(count = 5) {
            const generatedEmployees = Array.from({ length: count }).map(() => {
                const isCompleted = faker.datatype.boolean();
                const completedDate = isCompleted
                    ? faker.date.between({ from: this.selectedFrom, to: this.selectedTo })
                    : null;

                return {
                    color: isCompleted ? 'var(--color-green)' : 'var(--color-yellow-dark)',
                    id: faker.string.uuid(),
                    value: faker.person.firstName() + ' ' + faker.person.lastName(),
                    income: faker.finance.amount({ min: 3000, max: 5000, fixed: 2 }),
                    expense: faker.finance.amount({ min: 200, max: 1000, fixed: 2 }),
                    profit: faker.finance.amount({ min: 2000, max: 4000, fixed: 2 }),
                    atDriver: faker.finance.amount({ min: 400, max: 800, fixed: 2 }),
                    atCompany: faker.finance.amount({ min: 2500, max: 3500, fixed: 2 }),
                    driverShare: faker.finance.amount({ min: 1000, max: 2000, fixed: 2 }),
                    payoutAmount: faker.finance.amount({ min: 100, max: 500, fixed: 2 }),
                    openAmount: !isCompleted
                        ? faker.finance.amount({ min: 200, max: 1000, fixed: 2 })
                        : 0,
                    isCompleted: isCompleted,
                    completedAt: completedDate,
                    isUnfinishedPast: !isCompleted && faker.datatype.boolean(), // Make some unfinished and add it to missed employee criteria
                };
            });

            return [...generatedEmployees];
        },
        async handleQuery() {
            this.isFetchingData = true;

            try {
                // Generate or fetch employee data
                const employees = this.generateEmployees(10); // Simulated data. Replace with your data fetching logic

                // Filter based on selected employee
                const filteredEmployees = this.selectedEmployee
                    ? employees.filter(emp => emp.value === this.selectedEmployee)
                    : employees;

                // Filter based on selected date range
                const dateFilteredEmployees = filteredEmployees.filter(emp => {
                    if (emp.completedAt) {
                        const completedDate = new Date(emp.completedAt);
                        return (
                            completedDate >= startOfDay(this.selectedFrom) &&
                            completedDate <= endOfDay(this.selectedTo)
                        );
                    }
                    return true; // Keep unfinished employees, regardless of date range
                });

                // Classify the remaining employees into completed and unfinished
                this.completedEmployees = dateFilteredEmployees.filter(emp => emp.isCompleted);
                this.unfinishedEmployees = dateFilteredEmployees.filter(emp => !emp.isCompleted);
                this.missedEmployees = employees
                    .filter(emp => emp.isUnfinishedPast)
                    .map(e => ({ ...e, color: 'red' }));
            } catch (error) {
                console.error(error);
                this.$toasted.show('An error occurred.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
        handleEmployeeSelect({ item }) {
            this.selectedEmployee = item;
        },
        showCsvImportModal() {
            this.csvImportModal.active = true;
        },
        handleDownload() {
            console.log('Download CSV clicked');
        },
        handleDateChange(date) {
            console.log(date);
            this.selectedFrom = date.from;
            this.selectedTo = date.to;
        },
        navigateToEmployee({ id }) {
            this.$router.push({
                name: 'employee-revenue',
                params: { id, employees: this.unfinishedEmployees },
            });
        },
    },
    async mounted() {
        await this.handleQuery();
        this.foundUsers = this.generateEmployees(10);
        await this.generateRandomExpenses();
        this.isFetchingData = false;
        this.$emit('onFinishLoading');
    },
};
</script>

<style lang="scss">
.SavingsCalculator {
    @extend %contentWrapper;
    @extend %page;

    .Headline {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .SavingsCalculator-Summary {
        margin-bottom: 25px;
        margin-top: 15px;

        p {
            font-size: 14px;
        }
    }

    .Toolbar {
        margin-bottom: 5px;
    }

    .SavingsCalculator-Tables {
        display: flex;
        gap: 90px;
        align-items: flex-start;

        > * {
            flex: 1;
        }

        .SavingsCalculator-Table-Completed {
            .Row {
            }
        }

        // flex-direction: column;
    }

    /* CSV Modal Drag-and-drop styles */

    a {
        text-decoration: underline;
    }

    .FileDropArea {
        border: 2px dotted rgba(0, 0, 0, 0.3);
        padding: 40px 20px;
        text-align: center;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        transition: background-color 0.3s, border-color 0.3s;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            border-color: rgba(0, 0, 0, 0.6);
        }

        p {
            margin: 0;
            padding: 0;
        }

        a {
            color: #007bff;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
</style>
