var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Checkbox",
    class: [{
      'Checkbox--hasError': _vm.hasError
    }, "Checkbox--".concat(_vm.size)]
  }, [_c('input', {
    attrs: {
      "type": "checkbox",
      "id": _vm.name || _vm.value,
      "name": _vm.name,
      "disabled": _vm.isDisabled
    },
    domProps: {
      "checked": _vm.value
    },
    on: {
      "change": _vm.handleChange
    }
  }), _c('label', {
    attrs: {
      "for": _vm.name
    }
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }