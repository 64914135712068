var render = function () {
  var _Object$assign;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.rows ? _c('div', {
    ref: "row",
    staticClass: "Row",
    class: ["is-".concat(_vm.variant), {
      'has-click': _vm.hasClick
    }, {
      'is-dropdown': _vm.hasChildren
    }, {
      'is-active': _vm.isActive
    }, {
      'is-read-only': _vm.isReadOnly
    }, {
      'has-hover': _vm.parentHover
    }, {
      'is-disabled': _vm.isDisabled
    }],
    on: {
      "click": _vm.onClick
    }
  }, [_c('div', _vm._b({
    ref: "parent",
    staticClass: "Parent",
    class: ["is-".concat(_vm.variant), {
      'is-active': _vm.isActive
    }, {
      'has-badge': _vm.hasBadge || _vm.items.children && _vm.items.children.length > 1
    }, {
      'has-warning': _vm.hasWarning
    }, {
      'has-error': _vm.hasError
    }],
    style: Object.assign({}, _vm.parentStyle, _vm.items.props && _vm.items.props.style, (_Object$assign = {}, _Object$assign['--color-badge'] = _vm.items.color || 'var(--color-blue-light)', _Object$assign)),
    on: {
      "mouseenter": function mouseenter($event) {
        return _vm.onMouseEnterParent(_vm.index);
      },
      "mouseleave": function mouseleave($event) {
        return _vm.onMouseLeaveParent(_vm.index);
      },
      "click": _vm.onClickParent
    }
  }, 'div', Object.assign({}, _vm.items.props), false), [_vm._l(_vm.rows, function (row, i) {
    return [_c('Column', {
      key: i,
      attrs: {
        "item": row,
        "index": _vm.index,
        "childIndex": i,
        "hasHeader": _vm.hasHeader
      },
      on: {
        "onClick": function onClick($event) {
          return _vm.$emit('onClickColumn', $event);
        }
      }
    })];
  }), _vm._t("parent-additional"), _vm.hasChildren ? _c('span', {
    staticClass: "Icon-close"
  }) : _vm._e()], 2), _vm.items.children ? _c('div', {
    ref: "children",
    staticClass: "Children",
    class: {
      'is-hidden': _vm.isHidden
    }
  }, [_vm._t("row-additional"), _vm._l(_vm.items.children, function (child, i) {
    return _c('Row', {
      key: i,
      class: i === 0 ? 'Row-Header' : '',
      attrs: {
        "parentHover": false,
        "items": child,
        "hasHeader": true,
        "index": i,
        "hasWarning": child.props && child.props.hasWarning
      },
      on: {
        "onClick": _vm.onClickChild,
        "mouseenter": function mouseenter($event) {
          return _vm.onMouseEnterChild(_vm.x);
        },
        "mouseleave": function mouseleave($event) {
          return _vm.onMouseLeaveChild(_vm.x);
        }
      }
    });
  })], 2) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }