<template>
    <div
        v-if="rows"
        class="Row"
        ref="row"
        :class="[
            `is-${variant}`,
            { 'has-click': hasClick },
            { 'is-dropdown': hasChildren },
            { 'is-active': isActive },

            { 'is-read-only': isReadOnly },
            { 'has-hover': parentHover },
            { 'is-disabled': isDisabled },
        ]"
        @click="onClick"
    >
        <div
            class="Parent"
            ref="parent"
            :style="{
                ...parentStyle,
                ...(items.props && items.props.style),
                ['--color-badge']: items.color || 'var(--color-blue-light)',
            }"
            v-bind="{ ...items.props }"
            :class="[
                `is-${variant}`,
                { 'is-active': isActive },
                { 'has-badge': hasBadge || (items.children && items.children.length > 1) },
                { 'has-warning': hasWarning },
                { 'has-error': hasError },
            ]"
            @mouseenter="onMouseEnterParent(index)"
            @mouseleave="onMouseLeaveParent(index)"
            @click="onClickParent"
        >
            <template v-for="(row, i) in rows">
                <Column
                    :item="row"
                    :key="i"
                    :index="index"
                    :childIndex="i"
                    :hasHeader="hasHeader"
                    @onClick="$emit('onClickColumn', $event)"
                />
            </template>
            <slot name="parent-additional" />
            <span class="Icon-close" v-if="hasChildren" />
        </div>
        <div
            class="Children"
            v-if="items.children"
            :class="{ 'is-hidden': isHidden }"
            ref="children"
        >
            <slot name="row-additional" />
            <Row
                v-for="(child, i) in items.children"
                :class="i === 0 ? 'Row-Header' : ''"
                :parentHover="false"
                :items="child"
                :key="i"
                :hasHeader="true"
                :index="i"
                :hasWarning="child.props && child.props.hasWarning"
                @onClick="onClickChild"
                @mouseenter="onMouseEnterChild(x)"
                @mouseleave="onMouseLeaveChild(x)"
            />
        </div>
    </div>
</template>
<script>
import Column from '@/components/Row-Column';

const Row = {
    components: {
        Column,
        Row: () => import('@/components/Row'),
    },
    props: {
        isDisabled: Boolean,
        isInitialActive: Boolean,
        hasHeader: Boolean,
        variant: String,
        items: Object,
        activeRow: Number,
        activeChildRow: Number,
        index: Number,
        hasBadge: Boolean,
        hasWarning: Boolean,
        hasError: Boolean,
        hasClick: Boolean,
        isDropdown: Boolean,
        isReadOnly: Boolean,
        parentHover: {
            type: Boolean,
            default: true,
        },
        preRemoveRow: Number,
    },
    watch: {
        isInitialActive(val) {
            this.isHidden = !val;
            this.isActive = val;
        },
        activeRow(val) {
            const { parent } = this.$refs;
            if (val === this.index) {
                parent.classList.add('is-active');
            } else {
                parent.classList.remove('is-active');
            }
        },
    },
    data() {
        return {
            isActive: false,
            isHidden: true,
        };
    },
    computed: {
        hasChildren() {
            return this.isDropdown && this.items?.children?.length > 1;
        },
        rows() {
            return this.items?.row;
        },
        parentStyle() {
            const { index, preRemoveRow } = this.$props;
            const gradient = `linear-gradient(-49deg, var(--color-red) 0%, rgba(143,38,51,0.97) 100%`;
            const style = `background: ${preRemoveRow === index ? '' : ''};`;
            return style;
        },
    },

    methods: {
        onMouseEnterParent(item) {
            this.$emit('onHover', item);
        },
        onMouseLeaveParent(item) {
            this.$emit('onLeave', item);
        },
        onMouseEnterChild(item) {
            this.$emit('onHover', item);
        },
        onMouseLeaveChild(item) {
            this.$emit('onLeave', item);
        },
        onClick(e) {
            this.$emit('onClick', {
                index: this.index,
                id: this.items?.id,
                isActive: this.isActive,
                event: e,
                item: this.items,
            });
        },
        onClickParent() {
            const { isDropdown, items } = this.$props;
            this.isActive = isDropdown && items.children?.length > 1 ? !this.isActive : false;
            this.isHidden = isDropdown && !this.isActive;
        },
        onClickChild({ index }) {
            this.$emit('onChildClick', { index });
        },
    },
};
export default Row;
</script>

<style lang="scss">
.Row {
    margin-bottom: 0px;
    transition: all 0.5s cubic-bezier(0.24, 0.6, 0.36, 1);
    will-change: auto;
    transform: translateZ(0);
    user-select: none;
    border-radius: 0px;

    &:not(.is-minimal) {
        > .Parent {
            border: solid 1px var(--color-border);
            border-bottom: none;
            border-top: none;
        }

        &:first-of-type {
            .Parent {
                border: solid 1px var(--color-border);
                border-bottom: none;
            }
        }

        &:last-of-type {
            .Parent {
                border: solid 1px var(--color-border);
                border-top: none;
            }
        }

        &:nth-child(2n) {
            > .Parent:not(.is-active) {
                background: var(--color-row-alternate);
            }
        }
    }

    &.is-disabled {
        pointer-events: none;
        opacity: 0.5;
        filter: grayscale(1);
    }

    &.is-minimal {
        margin-bottom: 0;
    }

    &.is-active {
        z-index: 1;
        position: relative;
    }

    &.Row-Header {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: var(--color-white);

        .Column {
            cursor: pointer;
        }
    }

    &.is-dropdown,
    &.has-click {
        cursor: pointer;

        &:hover {
            > .Parent {
                background-color: var(--color-row-hover) !important;
            }
        }

        .Icon-close {
            background-color: var(--color-white);
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            right: 4px;
            top: 50%;
            opacity: 0;
            transform-origin: center;
            transform: scale(0.75) translateY(-50%);
            will-change: transform;
            cursor: pointer;
            border-radius: 3px;

            &::before {
                position: absolute;
                width: 2px;
                height: 70%;
                content: '';
                transform: translate(-50%, -50%) rotateZ(45deg);
                left: 50%;
                top: 50%;
                background-color: var(--color-blue-light);
            }

            &::after {
                position: absolute;
                width: 2px;
                height: 70%;
                content: '';
                left: 50%;
                transform: rotateZ(-45deg) translate(-120%, 10%);
                background-color: var(--color-blue-light);
            }
        }
    }

    &.is-read-only {
        pointer-events: none;
    }

    .Icon-close {
        display: none;
        transition: all 0.195s cubic-bezier(0.39, 0.58, 0.54, 1.14);
    }

    .Children {
        transition: all 0.5s cubic-bezier(0.24, 0.6, 0.36, 1);
        will-change: auto;
        transform: translateZ(0);
        display: block;
        position: relative;
        z-index: 100;
        margin: 6px 0 20px;
        // border: solid 1px var(--color-border);

        .Row {
            width: 95%;
            margin: 0 auto;

            &:last-child {
                .Parent {
                    border: solid 1px var(--color-border);
                    border-top: none;
                }
            }

            &:hover {
                > .Parent {
                    background-color: var(--color-row-hover) !important;
                }
            }

            .Parent {
                border: solid 1px var(--color-border);
                border-bottom: none;
                border-top: none;
            }

            &.Row-Header {
                position: sticky;
                top: 0;
                margin-top: -6px;
                z-index: 100;
                background-color: var(--color-white);
            }
        }

        .Chart {
            margin-top: 6px;
            margin-bottom: 12px;
            transform: translate(0, 0) !important;
        }

        &.is-hidden {
            display: none;
        }
    }

    .Parent {
        background-color: var(--color-white);
        padding: 0 25px;
        display: flex;
        column-gap: 10px;
        justify-content: space-between;
        transition: all 0.3s cubic-bezier(0.24, 0.6, 0.36, 1);
        transform: translateZ(0);
        margin-bottom: 0;
        position: relative;
        overflow: hidden;
        box-shadow: none;
        border-radius: 0;

        @media (max-width: 1200px) {
            width: 1100px;
        }

        &.is-minimal {
            padding: 0 0 0 15px;
            box-shadow: none;
            border-radius: 0;
            border-bottom: solid 1px rgba(color(blue-light), 0.2);
            margin-bottom: 0;

            .Column {
                padding: 5px 0 8px;
            }
        }

        & .red-value {
            color: red;
        }

        &.is-active,
        &.has-hover:hover {
            background: linear-gradient(-42deg, color(blue-light) -30%, color(blue-dark) 100%);
            margin-top: 0px;

            .Icon-close {
                transform: translateY(-50%) scale(1);
                opacity: 1;
            }

            .Name {
                color: rgba(#fff, 0.75);
            }

            .Button span {
                color: var(--color-text-black);
            }

            .Value {
                color: #fff;

                > * {
                    color: #fff;
                    fill: #fff;
                    border-color: #fff;
                }

                svg {
                    all: unset;
                    fill: #fff;
                }
            }

            & .red-value {
                color: #fe8484;
            }
        }

        &.has-badge,
        &.has-warning {
            &::before {
                position: absolute;
                content: '';
                display: block;
                left: 0;
                height: 90%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 6px;
                //border-radius: 4px;
                background-color: var(--color-badge);
            }
        }

        &.has-warning {
            &::before {
                background-color: var(--color-warning);
            }
        }

        &.has-error {
            &::before {
                background-color: var(--color-red);
            }
        }
    }

    img {
        width: 50px;
        height: 50px;
        background-color: var(--color-text-gray-dark);
        border-radius: 50%;
        border: solid 2px #fff;
    }
}
</style>
