var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "Chart",
    class: _vm.chartClass
  }, [_vm.chartType === 'pie' && _vm.secondValues.length > 0 ? _c('div', {
    staticClass: "ChartsWrap"
  }, [_c('div', {
    staticClass: "ChartContainer"
  }, [_vm.title ? _c('h2', {
    domProps: {
      "innerHTML": _vm._s(_vm.title || '<br />')
    }
  }) : _vm._e(), _vm.chartOptions && _vm.series.length ? _c('apexchart', {
    attrs: {
      "type": _vm.chartType,
      "height": _vm.chartHeight,
      "width": _vm.chartWidth,
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "ChartContainer"
  }, [_vm.title ? _c('h2', {
    domProps: {
      "innerHTML": _vm._s(_vm.secondValuesTitle || '<br />')
    }
  }) : _vm._e(), _vm.secondChartOptions && _vm.secondSeries.length ? _c('apexchart', {
    attrs: {
      "type": _vm.chartType,
      "height": _vm.chartHeight,
      "width": _vm.chartWidth,
      "options": _vm.secondChartOptions,
      "series": _vm.secondSeries
    }
  }) : _vm._e()], 1)]) : _vm.renderSeparately && _vm.secondValues.length > 0 ? _c('div', {
    staticClass: "ChartsWrap"
  }, [_c('div', {
    staticClass: "ChartContainer"
  }, [_vm.title ? _c('h2', {
    domProps: {
      "innerHTML": _vm._s(_vm.title || '<br />')
    }
  }) : _vm._e(), _vm.chartOptions && _vm.series.length ? _c('apexchart', {
    attrs: {
      "type": _vm.chartType,
      "height": _vm.chartHeight,
      "width": _vm.chartWidth,
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "ChartContainer"
  }, [_vm.title ? _c('h2', {
    domProps: {
      "innerHTML": _vm._s(_vm.secondValuesTitle || '<br />')
    }
  }) : _vm._e(), _vm.secondChartOptions && _vm.secondSeries.length ? _c('apexchart', {
    attrs: {
      "type": _vm.chartType,
      "height": _vm.chartHeight,
      "width": _vm.chartWidth,
      "options": _vm.secondChartOptions,
      "series": _vm.secondSeries
    }
  }) : _vm._e()], 1)]) : _c('div', [_c('h2', [_vm._v(" " + _vm._s(_vm.title) + " "), _vm._t("default")], 2), _vm.chartOptions && _vm.series.length ? _c('apexchart', {
    attrs: {
      "type": _vm.chartType,
      "height": _vm.chartHeight,
      "width": _vm.chartWidth,
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }