var render = function () {
  var _attrs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    ref: "dropdown",
    staticClass: "Dropdown",
    class: [{
      'is-small': _vm.size === 'small'
    }, {
      'is-open': _vm.dropdownIsOpen
    }, {
      'is-single-column': _vm.items.length < 10
    }],
    on: {
      "click": _vm.toggleDropdown
    }
  }, [_c('div', [_c('span', {
    staticClass: "Selected",
    class: {
      'not-selected': !_vm.internalSelected
    }
  }, [_vm._v(" " + _vm._s(_vm.getItemLabel(_vm.internalSelected || _vm.placeholder || _vm.items[0])) + " ")]), (_vm.selected || _vm.internalSelected) && _vm.hasRemoveIcon ? _c('IconCross', {
    staticClass: "Icon--Cross",
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClear.apply(null, arguments);
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "Dropdown-Wrapper"
  }, [_c('svg', {
    ref: 'dropdownIcon',
    class: 'Icon-Dropdown',
    attrs: (_attrs = {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "19",
      "height": "19",
      "viewBox": "0 0 19 19",
      "svg-inline": '',
      "fill": 'transparent',
      "stroke-width": '3',
      "stroke": '#555'
    }, _attrs["height"] = '19', _attrs["width"] = '19', _attrs["role"] = 'presentation', _attrs["focusable"] = 'false', _attrs["tabindex"] = '-1', _attrs)
  }, [_c('g', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-width": "3",
      "d": "M.5.5v18h18V.5H.5z"
    }
  }), _c('path', {
    attrs: {
      "d": "M5 8l4.523 4L14 8.04"
    }
  })])]), _c('portal', {
    attrs: {
      "to": "dropdown"
    }
  }, [_c('div', {
    staticClass: "Dropdown-Overlay",
    class: {
      'is-open': _vm.dropdownIsOpen
    }
  }), _vm.dropdownIsOpen ? _c('div', {
    ref: "dropdownItems",
    staticClass: "Dropdown-Items",
    class: {
      'is-open': _vm.dropdownIsOpen,
      'is-left': _vm.dropdownIsLeft
    },
    style: _vm.portalStyles()
  }, [_vm.hasSearch ? _c('div', {
    staticClass: "Search"
  }, [_c('Input', {
    attrs: {
      "placeholder": "Suchen",
      "keyName": "query"
    },
    on: {
      "onKeyPress": _vm.handleSearch
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "Dropdown-Items-Scroll-Wrapper",
    style: _vm.dropdownStyle
  }, _vm._l(_vm.filteredItems, function (item, i) {
    return _c('span', {
      key: i,
      staticClass: "Dropdown-Item",
      class: [{
        'is-selected': _vm.getItemLabel(item) === _vm.selected || _vm.getItemLabel(item) === _vm.internalSelected
      }],
      on: {
        "click": function click($event) {
          return _vm.handleItemClick(item);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.getItemLabel(item)) + " ")]);
  }), 0)]) : _vm._e()])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }