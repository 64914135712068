<template>
    <div
        class="Dropdown"
        ref="dropdown"
        v-click-outside="closeDropdown"
        :class="[
            { 'is-small': size === 'small' },
            { 'is-open': dropdownIsOpen },
            { 'is-single-column': items.length < 10 },
        ]"
        @click="toggleDropdown"
    >
        <div>
            <span class="Selected" :class="{ 'not-selected': !internalSelected }">
                {{ getItemLabel(internalSelected || placeholder || items[0]) }}
            </span>

            <IconCross
                v-if="(selected || internalSelected) && hasRemoveIcon"
                @click.native="handleClear"
                class="Icon--Cross"
            />
            <div class="Dropdown-Wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" v-bind:svg-inline="''" v-bind:fill="'transparent'" v-bind:stroke-width="'3'" v-bind:stroke="'#555'" v-bind:height="'19'" v-bind:width="'19'" v-bind:class="'Icon-Dropdown'" v-bind:ref="'dropdownIcon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd" stroke="currentColor"><path stroke-width="3" d="M.5.5v18h18V.5H.5z"/><path d="M5 8l4.523 4L14 8.04"/></g></svg>

                <portal to="dropdown">
                    <div class="Dropdown-Overlay" :class="{ 'is-open': dropdownIsOpen }"></div>
                    <div
                        v-if="dropdownIsOpen"
                        class="Dropdown-Items"
                        ref="dropdownItems"
                        :class="{ 'is-open': dropdownIsOpen, 'is-left': dropdownIsLeft }"
                        :style="portalStyles()"
                    >
                        <div v-if="hasSearch" class="Search">
                            <Input
                                placeholder="Suchen"
                                keyName="query"
                                @onKeyPress="handleSearch"
                            />
                        </div>
                        <div class="Dropdown-Items-Scroll-Wrapper" :style="dropdownStyle">
                            <span
                                v-for="(item, i) in filteredItems"
                                class="Dropdown-Item"
                                :class="[
                                    {
                                        'is-selected':
                                            getItemLabel(item) === selected ||
                                            getItemLabel(item) === internalSelected,
                                    },
                                ]"
                                :key="i"
                                @click="handleItemClick(item)"
                            >
                                {{ getItemLabel(item) }}
                            </span>
                        </div>
                    </div>
                </portal>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import IconCross from '@/components/icons/IconCross';
import Fuse from 'fuse.js';
import Input from '@/components/widgets/Input';

export default {
    components: {
        Input,
        IconCross,
    },
    props: {
        hasRemoveIcon: {
            type: Boolean,
            default: true,
        },
        hasSearch: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'default',
        },
        selected: {
            type: [String, Number, Boolean, Object],
        },
        dropdownHeight: [String, Number],
        items: {
            type: Array,
        },
        placeholder: {
            type: String,
        },
        size: {
            type: String,
            default: 'small',
        },
    },
    data: () => {
        return {
            filteredItems: [],
            dropdownStyle: {},
            dropdownIsOpen: false,
            dropdownIsLeft: false,
            internalSelected: null,
        };
    },
    directives: {
        ClickOutside,
    },
    watch: {
        selected(newValue) {
            if (newValue !== this.internalSelected) {
                this.internalSelected = newValue;
            }
        },
        dropdownIsOpen(val) {
            if (val) {
                this.filteredItems = this.items;

                setTimeout(() => {
                    this.calculateDropdownWidth();
                    this.$refs.dropdownItems?.querySelector?.('input')?.focus?.();
                }, 0);
            }
        },
        items(val) {
            this.filteredItems = val;
        },
    },
    methods: {
        handleSearch({ query }) {
            if (query.length === 0) {
                this.filteredItems = this.items;
                return;
            }
            const options = {
                minMatchCharLength: 2,
                threshold: Number.isNaN(Number(query)) ? 0.3 : 0.2,
                keys: ['value', 'label'],
            };
            const fuse = new Fuse(this.items, options);
            const result = fuse.search(query);
            this.filteredItems = result.map(r => r.item);
        },
        getItemLabel(item) {
            return item.value || item;
        },
        async calculateDropdownWidth() {
            const columnCount = this.variant === 'single-column' ? 1 : 3;
            await this.setColumnCount(columnCount);

            let { dropdownIcon, dropdownItems } = this.$refs;

            this.dropdownItemsWidth = dropdownItems?.offsetWidth;
            let { left, top } = dropdownIcon?.getBoundingClientRect?.() || {};

            let isRight = left + this.dropdownItemsWidth > window.innerWidth;
            for (let i = 5; i > 0 && isRight; i--) {
                this.setColumnCount(i);
                let { left, top } = dropdownIcon?.getBoundingClientRect?.() || {};
                isRight = left + this.dropdownItemsWidth > window.innerWidth;
            }
        },
        portalStyles() {
            const { dropdownIcon } = this.$refs;
            const { left, top } = dropdownIcon?.getBoundingClientRect?.() || {};
            return {
                position: 'fixed',
                left: `${left}px`,
                top: `${20 + top}px`,
            };
        },
        toggleDropdown(e) {
            this.dropdownIsOpen = !this.dropdownIsOpen;
        },
        closeDropdown(e) {
            if (this.$refs.dropdownItems?.contains?.(e?.target)) {
                return;
            }

            this.dropdownIsOpen = false;
        },
        handleClear() {
            this.$emit('onItemSelect', { item: null });
        },
        handleItemClick(item) {
            // Emit the event instead of changing the internal state directly
            this.$emit('onItemSelect', { item });
            // Close the dropdown
            this.closeDropdown();
            this.calculateDropdownWidth();
        },
        async setColumnCount(columns = 3) {
            if (columns === 0) {
                return;
            }
            this.dropdownStyle = {
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
            };
            new Promise(resolve => {
                setTimeout(() => {
                    const dropdownItems = this.$refs.dropdownItems;
                    const { right } = dropdownItems?.getBoundingClientRect?.() || {};
                    const isRight = right > window.innerWidth;
                    if (isRight) {
                        this.setColumnCount(columns - 1);
                    }
                    resolve();
                }, 200);
            });
        },
    },
    beforeDestroy() {
        // delete dropdown form dom
        const dropdown = this.$refs.dropdown;
        this.filteredItems = this.items;
        dropdown?.parentNode?.removeChild?.(dropdown);
    },
    mounted() {
        this.$nextTick(() => {
            this.calculateDropdownWidth();
            this.filteredItems = this.items;
            if (this.selected) {
                this.internalSelected = this.selected;
            }
        });
    },
};
</script>

<style lang="scss">
.Dropdown {
    font-weight: 700;
    color: var(--color-text-black);
    transition: all 0.3s ease-out;
    font-size: inherit;
    cursor: pointer;
    position: relative;
    user-select: none;
    perspective: 800px;
    z-index: 99;
    padding-right: 10px;
    background: rgba(0, 0, 0, 0.01);
    display: inline-block;
    border: solid 1px var(--color-text-inactive);
    border-radius: 5px;
    perspective-origin: 100% 0%;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        justify-content: center;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
    }

    &.has-error {
        border-color: color(red);
        color: color(red);
    }

    &.is-small {
        .Selected {
            padding: 4px 6px;
            font-size: 14px;
        }
    }

    &.is-open {
        z-index: 3;

        .Icon-Dropdown {
            polyline {
                transform: rotateZ(180deg);
                transform-origin: center;
            }
        }
    }

    .Icon--Cross {
        cursor: pointer;
        margin: 5px;
        padding: 6px;
        box-sizing: content-box;
        position: relative;
        z-index: 2;

        &:hover {
            background: color(grey);
        }
    }
}

.Dropdown-Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    z-index: 99;
    transition: all 0.3s ease-out;
    opacity: 0;
    visibility: hidden;

    &.is-open {
        opacity: 1;
        visibility: visible;
    }
}

.Dropdown-Wrapper {
    position: relative;
    z-index: 999;
}

.Dropdown-Items {
    opacity: 0;
    max-height: 0;
    visibility: hidden;
    background-color: var(--color-white);
    border-radius: 10px;
    border: solid 1px var(--color-text-inactive);
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25);
    padding: 20px 0;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 999;
    transform: translateX(-25px) translateZ(-50px) rotateX(-50deg);
    transform-origin: top;
    will-change: transform;
    min-width: 200px;

    .Dropdown-Item {
        display: block;
        visibility: hidden;
        opacity: 0;
        color: var(--color-text-black);
        font-size: 0.875rem;
        padding: 10px 18px;
        border: solid 1px rgba(0, 0, 0, 0.05);
        flex: 1 0 34%;
        line-height: 1.25;
        min-width: max-content;
        cursor: pointer;
        font-weight: 700;

        &:last-child {
            margin-bottom: 0;
        }

        &.is-selected {
            background-color: var(--color-bg);
            filter: brightness(0.8);
        }

        &:hover {
            background-color: var(--color-bg);
            filter: brightness(0.8);
        }
    }

    &.is-open {
        opacity: 1;
        visibility: visible;
        max-height: 700px;
        transform: translateX(-25px) translateZ(0px) rotateX(0deg);

        &.is-left {
            transform: translateX(calc(-100% + 45px)) translateZ(0px) rotateX(0deg);
        }

        .Dropdown-Item {
            opacity: 1;
            visibility: visible;
        }
    }

    &::before {
        position: absolute;
        content: '';
        display: block;
        top: -10px;
        left: 25px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 15px solid var(--color-white);
    }

    &.is-left {
        transform: translateX(calc(-100% + 45px)) translateZ(-50px) rotateX(-50deg);

        &::before {
            left: unset;
            right: 25px;
        }
    }

    .Dropdown-Items-Scroll-Wrapper {
        max-height: 320px;
        overflow-y: scroll;
        display: grid;
    }
}

.Selected {
    padding: 6px 12px;
    transition: width 0.3s;
    height: 100%;
    display: flex;
    align-items: center;

    &.not-selected {
        background: rgba(0, 0, 0, 0);
    }
}

.Icon-Dropdown {
    display: block;

    polyline {
        transition: all 0.2s;
        transform-origin: center;
    }
}

.Search {
    border: solid 1px var(--color-text-inactive);
    border-radius: 4px;
    margin: -8px 10px 0px;
    display: block;
}
</style>
