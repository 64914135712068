var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Toolbar"
  }, [_c('Button', {
    staticClass: "MenuIcon",
    attrs: {
      "size": "small",
      "variant": "plain"
    },
    on: {
      "onClick": _vm.toggleMenu
    }
  }, [_c('div', {
    staticClass: "MenuIcon"
  }, [_c('div', {
    staticClass: "Hamburger"
  }, [_c('div', {
    staticClass: "Line"
  }), _c('div', {
    staticClass: "Line"
  }), _c('div', {
    staticClass: "Line"
  })]), _vm._v(" Optionen ")])]), _c('div', {
    staticClass: "ToolbarItems",
    class: {
      'is-visible': _vm.isMenuOpen
    }
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "Backdrop",
    class: [{
      'is-visible': _vm.isMenuOpen
    }],
    on: {
      "click": _vm.toggleMenu
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }