<template>
    <div class="EmployeesTable Row-Wrap" :class="[{ 'is-empty': mEmployees.length === 0 }]">
        <!-- Header row for the table -->
        <Row
            v-if="mEmployees.length > 0"
            :parentHover="false"
            :items="{ row: header }"
            :key="-1"
            :index="-1"
            @onClickColumn="handleHeaderClick"
            class="Row-Header"
        />

        <!-- Rows for the employees data -->
        <Row
            v-for="(employee, i) in mEmployees"
            :items="employee"
            :key="i"
            :index="i"
            :hasBadge="hasBadge"
            @onHover="activeRow = i"
            @onLeave="activeRow = -1"
            @onClick="() => $emit('onClick', employee)"
        />

        <!-- Empty State when no data is present -->
        <EmptyState v-if="mEmployees.length === 0" />
    </div>
</template>

<script>
import { format } from 'date-fns';
import Row from '@/components/Row';
import EmptyState from '@/components/EmptyState.vue';
import { euro } from '@/lib/helper';

export default {
    name: 'EmployeeTable',
    components: {
        Row,
        EmptyState,
    },
    props: {
        employees: {
            type: Array,
            required: true,
        },
        hasBadge: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            activeRow: -1,
            sortState: {
                column: 'Fahrer',
                ascending: true,
            },
        };
    },
    computed: {
        header() {
            return [
                { name: 'Fahrer', value: null },
                // { name: 'Umsatz (€)', value: null },
                // { name: 'Ausgabe (€)', value: null },
                // { name: 'Gewinn (€)', value: null },
                // { name: 'Bei Fahrer (€)', value: null },
                // { name: 'Bei Unternehmen (€)', value: null },
                // { name: 'Rücklage Anteil (€)', value: null },
                // { name: 'Rückgelegter Betrag (€)', value: null },
                { name: 'Offener Betrag (€)', value: null },
                { name: 'Abgerechnet am', value: null },
            ].map(header => ({
                ...header,
                name: `${header.name}${this.sortIndicator(header.name)}`,
            }));
        },
        mEmployees() {
            return this.employees.length > 0
                ? this.sortEmployees([...this.formatEmployees(this.employees)])
                : [];
        },
    },
    methods: {
        sortIndicator(columnName) {
            if (this.sortState.column === columnName) {
                return this.sortState.ascending ? ' ▲' : ' ▼';
            }
            return '';
        },
        handleHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending;
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true;
            }
        },
        sortEmployees(arr) {
            const { column, ascending } = this.sortState;
            return arr.sort((a, b) => {
                const aEntry = a.row.find(item => item.name === column);
                const bEntry = b.row.find(item => item.name === column);
                const aVal = aEntry?.rawValue || aEntry?.value;
                const bVal = bEntry?.rawValue || bEntry?.value;
                if (ascending) {
                    return aVal > bVal ? 1 : -1;
                }
                return aVal < bVal ? 1 : -1;
            });
        },
        entryToRender(employee) {
            if (employee.isCompleted) {
                return {
                    name: 'Abgerechnet am',
                    value: employee.isCompleted
                        ? format(new Date(employee.completedAt), 'dd.MM.yyyy')
                        : '--',
                };
            }
            return {
                name: '',
                props: {
                    keyName: 'isVisible',
                    value: 'Abrechnen',
                    variant: 'minimal',
                    size: 'small',
                    id: employee.id,
                    onClick: () =>
                        this.$emit('onPayClick', {
                            id: employee.id,
                        }),
                },
                component: 'Button',
            };
        },
        formatEmployees(employees) {
            return employees.map(employee => {
                return {
                    color: employee.color,
                    row: [
                        { name: 'Fahrer', value: employee?.value || '' },
                        // { name: 'Umsatz (€)', value: `${euro(employee.income).format()}€` },
                        // { name: 'Ausgabe (€)', value: `${euro(employee.expense).format()}€` },
                        // { name: 'Gewinn (€)', value: `${euro(employee.profit).format()}€` },
                        // { name: 'Bei Fahrer (€)', value: `${euro(employee.atDriver).format()}€` },
                        // {
                        //     name: 'Bei Unternehmen (€)',
                        //     value: `${euro(employee.atCompany).format()}€`,
                        // },
                        // {
                        //     name: 'Fahrer Anteil (€)',
                        //     value: `${euro(employee.driverShare).format()}€`,
                        // },
                        // {
                        //     name: 'Ausgezahlter Betrag (€)',
                        //     value: `${euro(employee.payoutAmount).format()}€`,
                        // },
                        {
                            name: 'Offener Betrag (€)',
                            value: `<span style="color:${
                                employee.openAmount ? 'var(--color-red)' : ''
                            }">${euro(employee.openAmount).format()}€</span>`,
                        },
                        this.entryToRender(employee),
                    ],
                };
            });
        },
    },
};
</script>

<style scoped lang="scss">
.EmployeesTable.Row-Wrap {
    width: 100%;
    // height: 92vh;
    // overflow: auto;
    top: 0;

    > * {
        min-width: 300px;
    }

    &.is-empty {
        > * {
            min-width: 0;
        }
    }

    .Hint {
        font-size: 10px;
    }
}
</style>
