<template>
    <svg width="10" height="10" viewBox="0 0 10 10" stroke-width="3" stroke="currentColor">
        <line x1=".25" x2="8.75" y1=".25" y2="8.75" />
        <line x1=".25" x2="8.75" y1=".25" y2="8.75" transform="rotate(-90 4.5 4.5)" />
    </svg>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
