var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.item.props && _vm.item.props.tooltip ? _vm.item.props.tooltip : null,
      expression: "item.props && item.props.tooltip ? item.props.tooltip : null"
    }],
    staticClass: "Column",
    on: {
      "click": function click(e) {
        return _vm.handleClick(e, _vm.item);
      }
    }
  }, 'div', _vm.item.props, false), [_vm.item.name && (_vm.index === -1 || _vm.hasHeader && _vm.index === 0) ? _c('span', {
    staticClass: "Name"
  }, [_vm._v(_vm._s(_vm.item.name))]) : _vm._e(), _vm.item ? _c(_vm.item.component || _vm.el, _vm._b({
    tag: "component",
    staticClass: "Value"
  }, 'component', _vm.item.props, false), [_vm.item.value ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.item.value)
    }
  }) : _vm._e()]) : _vm._e(), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }